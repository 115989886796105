// El Header es el titulo de la columna
// El accessor es el KEY de los datos que van a contruir la columna (KEY: VALUE)


// funcion que pasa num a pesos (formatter.format(value))
const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
});

// funcion que pasa num a porcentaje (percent.format(value/100))
const percent = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});


/* columnas solo gerencia */
export const ColumnsPreciosGerente = [

    {
        Header: "Codigo",
        accessor: "ItemCode",
    },
    {
        Header: "Nombre",
        accessor: "ItemName",
    },

    {
        //pvp con iva
        Header: "Precio",
        accessor: "Price",
        Cell: ({ value }) => (value.Price)
        

    },

    {
        Header: "Moneda",
        accessor: "Currency",
        Cell: ({ value }) => { return isNaN(value.Currency) ? '-' : value.Currency }
    },

    {
        Header: "Codigo Grupo",
        accessor: "ItmsGrpCod",
    },
    {
        Header: "Nombre grupo",
        accessor: "ItmsGrpNam",
    },
    {
        Header: "Stock",
        accessor: "Stock",
    },

]

/*columnas detalle de pedido */
export const ColumnsOrderDetail = [
    // {
    //     Header: "#",
    // },
    // {
    //     Header: '#pedido'
    // },
    {
        Header: "Articulo",
        accessor: "Articulo",
        // Cell: ({value}) => <input disabled={true} className="inputsNoStyle textColorGrey" value={value}/>
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion"
    },
    {
        Header: "Cantidad",
        accessor: "Cantidad",
        //Cell: ({value}) => <input disabled={true} className="inputsNoStyle textColorGrey" value={value}/>
    },
    {
        Header: "pvp",
        accessor: "Pvp",
        Cell: ({ value }) => formatter.format(value.Pvp)
    },
    {
        Header: "pvp s/iva",
        accessor: "PvpSinIva",
        Cell: ({ value }) => formatter.format(value.PvpSinIva)
    },
    {
        Header: "MUP",
        accessor: "MUP",
        Cell: ({ value }) => {
             return value.MUP + " %" }
    },
    {
        Header: "Costo s/iva",
        accessor: "CostoSinIva",
        // Cell: ({ value }) => formatter.format(value.CostoSinIva)
          
        Cell: ({ value }) => {
            //console.log(value);
            const pvp = value.Pvp; 
            const bono = value.Bono; 
            const mup = value.MUP;
            const costNorm = value.CostoSinIva; 
            const markup = (1 + (mup / 100));
    
            // Asegúrate de que Pvp y Bono sean números válidos
            const validBono = bono !== undefined && bono !== null && !isNaN(bono) ? bono : 0;
            const validPvp = pvp !== undefined && pvp !== null && !isNaN(pvp) ? pvp : 0;
    
            // Realiza el cálculo
            const result = validPvp !== 0 ? ((validPvp - validBono) / 1.21) / markup : 0;
            // Retorna el resultado formateado o el costo normal
            return validBono === 0 ? formatter.format(costNorm) : formatter.format(result);
        }
    },
    {
        Header: "Total s/iva",
        accessor: "sumCostoSinIva",
        Cell: ({ value }) => {
          return  formatter.format(value.sumCostoSinIva)
        }
    },
    {
        Header: "Bono x Prod",
        accessor: "Bono",
        Cell: ({ value }) => {
            if (!value || typeof value !== "object") return formatter.format(0);
    
            const validValue = value.Bono !== undefined && value.Bono !== null && !isNaN(value.Bono)
                ? value.Bono
                : 0;
    
            return formatter.format(validValue);
        },
    }
]

/* columnas lista de precio */
export const ColumnsListaPrecios = [

    // {
    //     Header: "id",
    //     accessor: "id__",
    // },
    {
        Header: "Codigo",
        accessor: "ItemCode",
    },
    {
        Header: "Nombre",
        accessor: "ItemName",
    },

    {
        //pvp con iva
        Header: "Precio",
        accessor: "Price",
        Cell: ({ value }) => formatter.format(value.Price)
    },

    {
        Header: "Moneda",
        accessor: "Currency",
        Cell: ({ value }) => { return isNaN(value.Currency) ? '-' : value.Currency }
    },
]

/* columnas lista de clientes */
export const ColumnsListaClientes = [
    {
        Header: "CUIT",
        accessor: "CUIT",
    },
    // {
    //     Header: "Cliente_ID",
    //     accessor: "Cliente_ID",
    // },
    {
        Header: "Cliente",
        accessor: "Cliente",
    },
    {
        Header: "Nombre_Fantasia",
        accessor: "Nombre_Fantasia",
        Cell: ({ value }) => { 
            // Si el valor es NaN o undefined, retorna un guion (-)
            return !value || isNaN(value) ? '-' : value; 
        }
    },
    // {
    //     Header: "Direccion_Facturacion",
    //     accessor: "Direccion_Facturacion",
    // },
    // {
    //     Header: "Provincia_Facturacion",
    //     accessor: "Provincia_Facturacion",
    // },
    // {
    //     Header: "Cod__Postal_Entrega",
    //     accessor: "Cod__Postal_Entrega",
    // },
    // {
    //     Header: "Telefono_1",
    //     accessor: "Telefono_1",
    // },
    // {
    //     Header: "Telefono_2",
    //     accessor: "Telefono_2",
    // },
    // {
    //     Header: "Notas",
    //     accessor: "Notas",
    // },
    // {
    //     Header: "DNotesBal",
    //     accessor: "DNotesBal",
    // },
    {
        Header: "OrdersBal",
        accessor: "OrdersBal",
        Cell: ({ value }) => 
            {
                return !value || isNaN(value.OrdersBal) ? '-' :  formatter.format(value.OrdersBal)
            }
    },
    {
        Header: "PymntGroup",
        accessor: "PymntGroup",
    },
    {
        Header: "Limite_Credito",
        accessor: "Limite_Credito",
        Cell: ({ value }) => 
            {
                return !value || isNaN(value.Limite_Credito) ? '-' : formatter.format(value.Limite_Credito)
            }
    },
    {
        Header: "DebtLine",
        accessor: "DebtLine",
        Cell: ({ value }) => 
            {
                return !value || isNaN(value.DebtLine) ? '-' : formatter.format(value.DebtLine)
            }
    },
    // {
    //     Header: "OrderBalFC",
    //     accessor: "OrderBalFC",
    // },
    // {
    //     Header: "Lista_Precios",
    //     accessor: "Lista_Precios",
    // },
    // {
    //     Header: "DNoteBalFC",
    //     accessor: "DNoteBalFC",
    // },
    // {
    //     Header: "DNoteBalSy",
    //     accessor: "DNoteBalSy",
    // },
    // {
    //     Header: "OrderBalSy",
    //     accessor: "OrderBalSy",
    // },
    // {
    //     Header: "ID_Dpto_Ventas",
    //     accessor: "ID_Dpto_Ventas",
    // },
    // {
    //     Header: "Fecha_de_Alta",
    //     accessor: "Fecha_de_Alta",
    // },
    // {
    //     Header: "Fecha_Ultima_Modificacion",
    //     accessor: "Fecha_Ultima_Modificacion",
    // },
    // {
    //     Header: "validFor",
    //     accessor: "validFor",
    // },
    // {
    //     Header: "validFrom",
    //     accessor: "validFrom",
    // },
    // {
    //     Header: "validTo",
    //     accessor: "validTo",
    // },
    // {
    //     Header: "Inactivo",
    //     accessor: "Inactivo",
    // },
    // {
    //     Header: "Inactivo_desde",
    //     accessor: "Inactivo_desde",
    // },
    // {
    //     Header: "Inactivo_hasta",
    //     accessor: "Inactivo_hasta",
    // },
    // {
    //     Header: "ShipToDef",
    //     accessor: "ShipToDef",
    // },
    // {
    //     Header: "Acuerdo",
    //     accessor: "Acuerdo",
    // },
    // {
    //     Header: "Publicidad",
    //     accessor: "Publicidad",
    // },
    // Cell: ({ value }) => { 
    //     console.log(value);  // Esto debería imprimir solo el valor, no un objeto
    //     // Si el valor es NaN o undefined, retorna un guion (-)
    //     return !value || isNaN(value) ? '-' : value; 
    // }

    {
        Header: "MUp %",
        accessor: "MUp",
        Cell: ({ value }) => 
            {
                return !value|| isNaN(value.MUp) ? '-' : parseInt(((100 / (100 - value.MUp)) - 1) * 100)
            }
        // Cell: ({ value }) => { return isNaN(value) ? '-' : value }
    },
    // {
    //     Header: "MUp_LineaB",
    //     accessor: "MUp_LineaB",
    // },
    // {
    //     Header: "Turno",
    //     accessor: "Turno",
    // },
    // {
    //     Header: "Grupo",
    //     accessor: "Grupo",
    // },
    // {
    //     Header: "Balance",
    //     accessor: "Balance",
    // },
    // {
    //     Header: "Descuento",
    //     accessor: "Descuento",
    // },
    // {
    //     Header: "Cod__Postal_Factu",
    //     accessor: "Cod__Postal_Factu",
    // },
    // {
    //     Header: "Direccion_Entrega",
    //     accessor: "Direccion_Entrega",
    // },
    // {
    //     Header: "Provincia_Entrega",
    //     accessor: "Provincia_Entrega",
    // },
    // {
    //     Header: "id__",
    //     accessor: "id__",
    // },
]

/* columnas lista condiciones de pago config */
export const ColumnsConfiguracion = [
    {
        Header: 'Codigo',
        accessor: 'GroupNum',
    },
    {
        Header: 'Nombre',
        accessor: 'PymntGroup',
    },
    {
        Header: 'Habilitado',
        accessor: 'Habilitado',
        // Cell: ({ value, onChange }) => { return (<input checked={value} type='checkbox' onChange={()=>onChange(!value)}/>) }
    },
]