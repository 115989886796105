import {createSlice} from '@reduxjs/toolkit'

export const ClientSlices = createSlice({
    name: 'clients',
    initialState: [],
    reducers: {
        addClients: (state, action) => {
            //state.push(action.payload)
            //state[0] = action.payload
            return action.payload
        }, 
        pushClients:(state, action)=>{
            return[...state, ...action.payload];
        }
    }
})

export const { addClients, pushClients} = ClientSlices.actions
export default ClientSlices.reducer