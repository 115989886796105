import { collection, doc, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Adddata, CreateUser, db } from '../../data'
import { useAuth, useGeneral } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import { addUserList } from '../../store/adminPanel/UserListSlices'
import { ToastError, ToastSucces } from '../tools'
import { List } from './List'
import { UserModal } from '.'
import newUserIco from '../../assets/images/agregar-contacto-green.png'
import { useNavigate } from 'react-router-dom'
import { GroupGeren } from './GroupGeren'

export const UserList = ({ permisos, hadleDetail, handleModalUser, subSection, setModUser, modUser }) => {
   

    const { formatDate } = useGeneral()
    const { signup, user, logout } = useAuth()
    const navigate = useNavigate()
    const userList = useSelector((state) => state.userList)
    const totalGeren = useSelector((state) => state.totalGeren)
    const openDetail = useSelector(state => state.openDetail)
    const [savingMod, setSavingMod] = useState(false)
    const [savingAdd, setSavingAdd] = useState(false)
    const [gn,setGn] = useState()

    useEffect(() => {
        const fetchData = async () => {
            setGn(totalGeren)
        };
        fetchData();
    }, []);
    
    const dispatch = useDispatch()
    const compareApellido = (a, b) => {
        return a.Apellido.localeCompare(b.Apellido)
    }

    const submitUserModification = async (e, modUser, user) => {
        //console.log(modUser)
        e.preventDefault()
        setSavingMod(true)

        try {

            let docRef = doc(db, 'Usuarios', user.UID)
            //aca se sube
  await Adddata(docRef, modUser) 
        } catch (error) {
            ToastError('No se pudo modificar')
        } finally {
            setSavingMod(false)

            handleModalUser(false)
            ToastSucces('Usuario actualizado')
        }
    }
//console.log(totalGeren)
//al crear un usuario se cerrara la sesion
    const submitCreateUser = async (e, modUser) => {
        e.preventDefault()
   
        const findAreaDeVentas = totalGeren.find(b => b.AreaDeVentas == modUser.AreaDeVentas)
        const pidCorrecto= (findAreaDeVentas[modUser.Permiso])
        // Encuentra el PID CORRECTO Y SU AREA DE VENTAS
        const gerenciaCorrecta = (userList.find(e => e.PID === findAreaDeVentas.Gerencia))
        // Encontrando el Nombre del gerente que le corresponde
        const gerenNombre = `${gerenciaCorrecta?.Nombre} ${gerenciaCorrecta?.Apellido}`
        

        try {
            setSavingAdd(true)
            const creden = await signup(modUser.Mail, 'Goldmund') 
            //console.log(creden.user.uid)
            //modUser.mail
            await CreateUser(
                {
                    ...modUser,
                    lastLogin: 0,
                    UID: creden.user.uid,
                    Mail: modUser.Mail,
                    AreaDeVentas:modUser.AreaDeVentas,
                    PID:pidCorrecto,
                    Id:findAreaDeVentas.Id,
                    Gerencia:gerenNombre,
                    Permiso:modUser.Permiso || 'Vendedor'
                    //TODO: ver de donde saco esto
                    // Gerencia: "",
                    // Id: "20",
                    // PID: "04",
                }
            )
        } catch (error) {
            console.log(error)
            ToastError('Fallo al crear usuario')
        } finally {
            setSavingAdd(false)
        // await logout();
        // navigate("/")
        }
    }

    useEffect(() => {
        // TODO: pasar a snapshot para mejor respuesta si se piensa utilizar mucho
        const handleGetUsers = async () => {
            let list = []

           const docs = await getDocs(collection(db, "Usuarios"))

            docs.forEach((doc) => {
                // console.log(typeof doc.data().lastLogin === 'object')
                let lastlog = '0'
                if (typeof doc.data().lastLogin === 'object') {
                    lastlog = formatDate(doc.data().lastLogin)
                }

                list.push(
                    {
                        ...doc.data(),
                        lastLogin: lastlog
                    }
                )
            })
            list.sort(compareApellido)

           dispatch(addUserList(list))  
        }
        handleGetUsers()
    }, [])



    return (
        <div className='p-2 mb-3'>

            <div className='mt-2'>
                <div className='d-flex justify-content-between'>
                    <h4 className='separar my-4'>
                        <b className='text-uppercase'>Lista de usuarios</b>
                    </h4>

                    <img
                        src={newUserIco}
                        alt="NEW_USER"
                        onClick={(e) => handleModalUser(true, 'add')}
                        className='cursorPointer h-75 addUserIco'
                    />
                </div>

                <List openDetail={openDetail} hadleDetail={hadleDetail} handleModalUser={handleModalUser} permisos={permisos}
                    submitUserModification={submitUserModification} savingMod={savingMod} subSection={subSection} />

                <UserModal subSection={subSection} user={subSection === 'mod' ? modUser : {}}
                    setModUser={setModUser} handleModalUser={handleModalUser} permisos={permisos}
                    submit={subSection === 'mod' ? submitUserModification : submitCreateUser} savingMod={savingMod} saving={savingAdd}
                />
            </div>
        </div>
    )
}
