import { createContext, useContext } from 'react';
import axios from "axios";
import { SLCred, PostOrders, db, Adddata } from '../data/index'
import { ToastError, ToastSucces, SlackErrorHandle } from '../components/tools/index'
import { query, updateDoc, doc } from 'firebase/firestore';

// Creamos un contexto 
export const GeneralContext = createContext();

//TESTING: cambiar para produccion => "Pedidos" / test => "Pedidos_Test"
export const pedidosCollectionFirebase = "Pedidos";
//export const pedidosCollectionFirebase = "Pedidos_Test";
//TESTING: cambiar para produccion => "mensajes" / test => "mensajes_Test"
export const mensajesCollectionFirebase = "mensajes";
//export const mensajesCollectionFirebase = "mensajes_Test";

// tipos de pedidos corresponden al numero (index + 1) //Normal => 1
export const tiposPedidos = ['Normal', 'Programa', 'Preventa', 'Factura Anticipo']

//USUARIOS ADMINISTRACION
//Funcion almacena los datos que retorne el contexto
export const useGeneral = () => {
    const context = useContext(GeneralContext)
    return context
};

//Funcion que proveera al contexto de los datos de logueo
export function GeneralProvider({ children }) {

    //Devuelve los datos de credenciales de service layer
    const datacred = SLCred()

    //Crea instancia de conexion con el service layer
    const Sl = (BaseURL) => {
        const ServiceLayer = axios.create({
            withCredentials: true,
            baseURL: BaseURL,
            responseType: 'json',
            //timeout: 600000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return ServiceLayer
    }

    //checkeo si esta la oferta en sap
    const getQuotations = async (url, ServiceLayer, U_PV_ID) => {
        try {
            const urldata = `Quotations?$filter=U_PV_ID eq \'${U_PV_ID}\'`
            const res = await ServiceLayer(url).get(urldata)
            return (res.data.value)

        } catch (error) {
            SlackErrorHandle(
                `${new Date()}: ${error.response.data.error}`
            )

            return (error.response.data.error.message.value)


        }

    }

    // funcion para subir el pedido autorizado a sap
    const uploadtoServiceLayer = async (orderInfo, userName) => {
        //console.log(orderInfo)
       // respuesta del chequeo
        const response = await getQuotations(datacred.BaseURL, Sl, orderInfo.Num_pedido)

        const docRef = query(doc(db, pedidosCollectionFirebase, orderInfo.ID))

        // si no esta cargado en sap
        if (!response.length > 0) {

            const resp = await PostOrders(datacred.BaseURL, Sl, orderInfo)

            //si se cargo correctamente
            if (resp.status === 201 && resp.data.DocNum) {

                // intenta actualizar firebase
                try {
                    await updateDoc(docRef, {
                        "Estado": 'Cargado en SAP',
                        "DocNum": resp.data.DocNum,
                        "Revision": userName,
                        "FechaDeRevision": new Date().toLocaleDateString(),
                    });

                    ToastSucces(`Datos cargados en SAP: Orden ${resp.data.DocNum}`)


                } catch (error) {

                    ToastError('Error de conexion')

                    SlackErrorHandle(
                        `${new Date()}: Order ID: ${orderInfo.ID} -> Error en la actualizacion del estado en firebase: -> ${resp}`
                    )
                }

                //si falla la carga
            } else {
                Adddata(docRef, { "SapResponse": { "Response": resp, "Fecha": new Date().toLocaleString() } })
                ToastError(`Error en la carga del pedido ${resp}`)

                SlackErrorHandle(
                    `${new Date()}: Order ID: ${orderInfo.ID} -> Error en la carga de pedido a SAP: -> ${resp}`
                )
            }
        } else {
            Adddata(docRef, { "SapResponse": { "Response": `Existe otro documento con ID: ${orderInfo.Num_pedido}`, "Fecha": new Date().toLocaleString() } })

            ToastError(`Existe otro documento con ID: ${orderInfo.Num_pedido}`)
            SlackErrorHandle(
                `${new Date()}: Order ID: ${orderInfo.ID} -> Existe otro documento con NumPedido: ${orderInfo.Num_pedido}`
            )
            console.log(`Existe otro documento con NumPedido: ${orderInfo.Num_pedido}`)

        }
    }


    const convertToDate = (dateString) => {
        const parts = dateString.split('/');
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1] - 1, 10);
            const year = parseInt(parts[2], 10);
            return new Date(year, month, day);
        }
        return null; // Devuelve null si el formato no es válido
    }
    // cambio de timestamp a dd/mm/aa
    const formatDate = (timestamp) => {
        if (timestamp) {
            const date = timestamp.toDate().toLocaleDateString(
                "es-AR",
                {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                }
            )
            return date
        }
    }

    //compara si 2 obj son igualess
    const objectCompare = (obj1, obj2) => {
        let keys1 = Object.keys(obj1)
        let keys2 = Object.keys(obj2)

        if (isObject(obj1) && isObject(obj2)) {

            if (keys1.length !== keys2.length) {
                return false
            }

            for (const key of keys1) {
                let val1 = obj1[key]
                let val2 = obj2[key]

                if (val1 !== val2) {
                    return false
                }
            }
            return true
        }
    }
    const isObject = (obj) => {
        return obj !== null && typeof obj === 'object'
    }
    //suma los las unidades totales
    const sumProducts = (productsL) => {
        let qty = []

        productsL.map(p => (qty.push(p.Cantidad)))

        return qty.reduce((acc, cur) => {
            return parseInt(acc) + parseInt(cur)
        }, 0)
    }

    const getToday = () => {
        let today = new Date()

        return today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDay()
    }

    return (
        <GeneralContext.Provider
            value={
                {
                    Sl, datacred, uploadtoServiceLayer, formatDate,
                    pedidosCollectionFirebase, tiposPedidos,
                    mensajesCollectionFirebase, objectCompare, convertToDate,
                    sumProducts, getToday
                }
            }
        >
            {children}
        </GeneralContext.Provider>
    );

}