import { createSlice } from "@reduxjs/toolkit";

const initialFileState = {
  file: null,
};

export const fileSlice = createSlice({
  name: 'fileData',
  initialState: initialFileState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
    },
    clearFile: (state) => {
      state.file = null;
    },
  },
});

export const { setFile, clearFile } = fileSlice.actions;
export default fileSlice.reducer;
