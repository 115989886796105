import React, { useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import {
    ExportTable, ColumnsOrderDetail,
    ColumnsPreciosGerente, ColumnsListaPrecios, ColumnsListaClientes, ColumnsConfiguracion
} from './index';
import { NavLink } from 'react-router-dom';
import { Skeleton } from './Skeleton';
import okIco from '../../assets/images/comprobado.png'
import failIco from '../../assets/images/cruzar.png'



export const TableTks = ({ section, data, handleChange, updating,
    modifiedDoclineValue }) => {

    //   console.log(section, handleChange, updating,
    //     modifiedDoclineValue)  

    const userData = useSelector(state => state.user)
    //lista de secciones con sus respectivas columnas
    const colList = [
        { section: 'CLIENTES', columns: ColumnsListaClientes },
        { section: 'PRECIOS', columns: ColumnsListaPrecios, columnsFull: ColumnsPreciosGerente },
        { section: 'ORDEN', columns: ColumnsOrderDetail },
        { section: 'CONFIGURACION', columns: ColumnsConfiguracion },
    ]

    const [doclines, setDoclines] = useState(false)
    //cambia la seleccion de columna en el caso de lista de precios (solo Direccion)
    const columns =
        userData.Permiso === 'Direccion'
            ? colList.find(c => c.section === section).columnsFull || colList.find(c => c.section === section).columns
            : colList.find(c => c.section === section).columns

    const detailsRef = useRef(null)
    const headRef = useRef(null)

    //listas de datos 
    const clientes = useSelector(state => state.clients)
    const precios = useSelector(state => state.price)
    const orderInfo = useSelector(state => state.orderData)
    const filter = useSelector(state => state.searchFilter)
    const priceSync = useSelector(state => state.priceSync)
    const clientSync = useSelector(state => state.clientSync)
    const pymntGroups = useSelector(state => state.condicionesDePago)

    const [lista, setLista] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    //ordenamiento
    const [sortedCol, setSortedCol] = useState(null)
    //true asc - false desc
    const [sortDirection, setSortDirection] = useState(true)
    // columnas visibles
    const [visibleColumns, setVisibleColumns] = useState(() => columns.map((col) => col.accessor))
    
    //referencia para exportar la tabla
    const tableTkRef = useRef(null)
    // console.log(orderInfo)
    //descPvp     descuentoPvpTotal
    
    //  maneja el ordenamiento por columna 
    const handleSort = (column) => {

        if (sortedCol === column) {
            setSortDirection(!sortDirection)
        } else {
            setSortedCol(column)
            setSortDirection(true)
        }
    }

    // maneja la visibilidad de columnas
    const handleToggleColumn = (accesor) => {
        let visCol = visibleColumns

        if (visCol.includes(accesor)) {
            visCol = visCol.filter((col) => col !== accesor)
        } else {
            visCol = [...visCol, accesor]
        }
        setVisibleColumns(visCol)
    }

    const sortedData = useMemo(() => {

        //filtra por el buscador
        const filteredData = lista.filter((row) =>
            Object.values(row).some((value) =>
                String(value).toLowerCase().includes(filter.toLowerCase())
            )
        )

        if (!sortedCol) {
            
            return filteredData
        }

        //ordena la lista
        const sorted = [...filteredData].sort((a, b) => {
            const aValue = a[sortedCol]
            const bValue = b[sortedCol]
        
            if (sortDirection) {
                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
            } else {
                return bValue > aValue ? 1 : bValue < aValue ? -1 : 0
            }
        })

        return sorted

    }, [lista, sortedCol, sortDirection, filter])


    const mupColorChange = (header, value) => {

        if (header === 'MUP') {
            let color = 'textGreen fw-bold'

            if (value > orderInfo.MUPPactado) {
                color = 'textRed fw-bold'
            }

            return color
        }
    }


    //setea la lista dependiendo de la seccion
    useEffect(() => {
        setIsLoading(true)
        let list = []

        if (section === 'ORDEN') {
            list = orderInfo.DocumentLines
        } else if (section === 'PRECIOS') {
            list = precios
        } else if (section === 'CLIENTES') {
            list = clientes
        } else if (section = 'CONFIGURACION') {
            list = pymntGroups
        }
        setLista(list)

        setIsLoading(false)

        return (() => {
            setIsLoading(true)
        })

    }, [columns, orderInfo, section, pymntGroups])


    // console.log(columns)

    return (
        <>
            {
                section !== 'CONFIGURACION'
                &&
                <div className='d-flex minAccordHeight pe-2 align-items-center transition'>

                    <details
                        ref={detailsRef}
                        className='columnsDet w-100 position-relative'
                    >
                        <summary
                            className='py-1 d-flex '
                        >
                            <div className='d-flex align-items-center pe-3' >
                                <div className='downBtn mx-2'></div>
                                <span className='pe-3 displayNoneSM'>
                                    Ocultar / Mostrar Columnas
                                </span>
                            </div>
                        </summary>

                        <div className='position-absolute index2000 clrBgPinkLight rounded-bottom p-2'>
                            {
                                columns.map((c) => (
                                    <label key={'ch' + c.Header} className='ms-2'>
                                        <input
                                            type="checkbox"
                                            checked={visibleColumns.includes(c.accessor)}
                                            onChange={() => handleToggleColumn(c.accessor)}
                                        />
                                        {c.Header}
                                    </label>
                                ))
                            }
                        </div>
                    </details>


                    <span className='WSpre fontSize14 px-2  text-end textColorGreyL'>
                        {
                            section === 'CLIENTES'
                                ? `Sincronizado: ${clientSync}`
                                : section === 'PRECIOS'
                                    ? `Sincronizado: ${priceSync}`
                                    : ''
                        }
                    </span>

                    {
                        ['CLIENTES', 'PRECIOS'].includes(section)
                        &&
                        <ExportTable tablereference={tableTkRef} section={section} />
                    }
                </div>
            }

            <div className='overflow-auto'>
                <table
                    ref={tableTkRef}
                    className="modTable border-bottom mb-2 table table-hover table-striped mb-0"
                >
                    <thead className='clrBgTableHead stickyHead'>
                        <tr>
                            {
                                columns
                                    .filter((c) => visibleColumns.includes(c.accessor))
                                    .map((c) => (
                                        <th
                                            ref={headRef}
                                            className='cursorPointer fw-bold fontSize17'
                                            key={c.Header}
                                            onClick={() => handleSort(c.accessor)}
                                        >
                                            {c.Header}
                                            {
                                                sortedCol === c.accessor
                                                &&
                                                <span>
                                                    {sortDirection ? ' ▼' : ' ▲'}
                                                </span>
                                            }
                                        </th>
                                    ))
                            }
                        </tr>
                    </thead>

                    <tbody>
                        {
                            isLoading
                                ?
                                (
                                    Array.from({ length: 5 }, (_, rowIndex) => (

                                        <tr key={rowIndex}>
                                            {
                                                visibleColumns.map((c, colIndex) => {

                                                    return (
                                                        <td key={colIndex}>
                                                            <Skeleton />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))

                                )
                                :

                                sortedData.map((e) => (

                                    <tr key={'e' + sortedData.indexOf(e)}>
                                        {
                                            visibleColumns.map((c) => {
                                                const colu = columns.find((f) => f.accessor === c)

                                                return (
                                                    <td
                                                        key={sortedData.indexOf(e) + colu.accessor}
                                                        title={colu.Cell ? colu.Cell({ value: e[c] }) : e[c]?.toString()}
                                                        className={`${modifiedDoclineValue ?
                                                            modifiedDoclineValue(sortedData.indexOf(e), e[c])
                                                            : ''
                                                            }`}
                                                    >
                                                        { // anidando como un campeon! si te la bancas pasa todo esto a las Cell de columns.js
                                                            colu.Header === 'Cliente'
                                                                ?
                                                                <NavLink
                                                                    className=""
                                                                    to={`/sections/clients/clientinfo?client=${e.Cliente_ID}`}
                                                                    title={e.Cliente ? e.Cliente : `cli${sortedData.indexOf(e)}`}
                                                                >
                                                                    {e[c]}
                                                                </NavLink>
                                                                : colu.Header === 'Habilitado'
                                                                    ? <img
                                                                        src={e[c] ? okIco : failIco}
                                                                        onClick={() => handleChange(sortedData[sortedData.indexOf(e)], !e[c])}
                                                                        disabled={updating}
                                                                        className='cursorPointer'
                                                                    />

                                                                    //<input checked={e[c]} type='checkbox' onChange={() => handleChange(!e[c])} />
                                                                    : colu.Cell
                                                                        ? <span className={mupColorChange(c, e[c])}>{colu.Cell({ value: e  })}</span>
                                                                        : e[c]
                                                        }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
