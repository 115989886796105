import { useSelector } from 'react-redux'
import peabody from '../../assets/images/peab.png'
import goldImg from '../../assets/images/goldImg.png'
import downIco from '../../assets/images/descargarIco.png'
import { useGeneral } from '../../context'
import html2pdf from 'html2pdf-jspdf2'
import { useEffect, useRef,useState} from 'react'



export const ExportOrder = ({ exportModal, setExportModal,
    cuentasBanca,  }) => {

    const { sumProducts } = useGeneral()
    const orderInfo = useSelector((state) => state.orderData)
    const isOpenModal = useSelector(state => state.openModal)
    const pdfRefOrd = useRef(null)
     const clients = useSelector(state => state.clients)

    const [whithIva,setWithIva] = useState(false)
    

    useEffect(() => {
        const clientEnc = clients.find((e) => e.CUIT === orderInfo.Cuit);
    
        if (clientEnc && clientEnc.Iva === 'EX' && clientEnc.liquidaIva === 'S') {
            setWithIva(true);
               // console.log('siniv')
        } else {
            setWithIva(false);
               // console.log('coniv')
        }
    }, [orderInfo, clients]);
    //console.log(whithIva)

    //funcion para formatear a pesos arg (formatter.format(num))
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    //console.log(orderInfo)
    //exportar a pdf
    const generatePDF = (ref) => {

        let opt = {
            margin: [3, 1, 3, 1],
            filename: `${orderInfo.Num_pedido + ' ' + orderInfo.Cliente}.pdf`,
            image: { type: 'png', quality: 1 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' }
        };

        html2pdf().from(ref.current).set(opt).save()
    }

    const pdfHandle = (ref) => {
        if (window.confirm("Confirmar descarga en PDF")) {
            try {
                generatePDF(ref)
            } catch (error) {
                console.log(error)
            }
        }
    }
    //-----------------------------------------------------------------------
    //------------------ Esta aca solo para no ------------------------------
    //------------------ modificar el costo en la cuenta --------------------
    //------------------ en la cuenta da correcto ---------------------------
    //------------------ solo que no modifico este numero --------------------
    //-----------------------------------------------------------------------
    
    const ContarCostoCorrectTemporal = ( pvp, bono, mup, costNorm) => {
        const markup = (1+(mup/100));
        const result = parseInt(((pvp - bono) / 1.21 )/ markup) ;
        // console.log(pvp)
        // console.log(bono)
        // console.log(costNorm)

       // console.log(result)
       if (bono === ''){
            return costNorm
       }else{
        return result
       }
    }
    //-----------------------------------------------------------------
    //-----------------------------------------------------------------
    //-----------------------------------------------------------------
    //-----------------------------------------------------------------
    //ESTILOS INLINE (necesarios para que funcione la libreria a (la fecha dec2023))
    const exportOrdStyle = {
        overflow: 'auto',
        margin: 'auto',
        maxWidth: '21cm',
        backgroundColor: 'white',
        position: 'relative',
        minWidth: '21cm',
        padding: '1.5rem',
    }
    const exportHeadStyle = {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
    }
    const imgSizeStyle = {
        height: '38px',
        width: 'auto'
    }
    const rowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
    }
    const cliInfoStyle = {
        backgroundColor: '#f3f3f3',
        border: '1px solid #d6d6d6',
        padding: '0.5rem',
        paddingTop: '1.5rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    }
    const col4Style = {
        display: 'flex',
        boxSizing: 'border-box',
        flex: '0 0 30%',
        maxWidth: '30%',
        flexDirection: 'column',
        gap: '0.5rem',
    }
    const exportDataStyle = {
        display: 'flex',
        marginBottom: '10px',
        borderLeft: '#bdbde6 solid 1px',
        flexDirection: 'column',
        position: 'relative',
    }
    const labelStyle = {
        position: 'absolute',
        top: '-14px',
        fontSize: '11px',
    }
    const labelSpanStyle = {
        paddingLeft: '0.4em',
        lineHeight: 'normal',
    }
    const tableStyle = {
        width: '100%',
        fontSize: 'small',
        captionSide: 'bottom',
        borderCollapse: 'collapse',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    }
    const tableBorderStyle = {
        border: '1px solid #d6d6d6',
    }
    const detailsStyle = {
        width: '85%',
        margin: 'auto',
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
        borderBottom: '#d6d6d6',
        captionSide: 'bottom',
        borderCollapse: 'collapse',
    }
    const bankLineStyle = {
        borderBottom: '1px solid #d6d6d6',
        flexWrap: 'wrap',
        display: 'flex',
    }
    const ccStyle = {
        width: '210px',
        textAlign: 'right',
        paddingRight: '.5rem',
        marginLeft: 'auto',
    }
    const numStyle = {
        fontWeight: '700', fontSize: '13px', marginRight: '0.5rem'
    }
 
    //console.log(orderInfo.Direccion_Entrega_New)
    
    return (
        <dialog className="modalBackdrop exportMod"
            open={exportModal}
        >

            <div
                ref={pdfRefOrd}
                style={exportOrdStyle}
            // className='exportOrderContent exportMinW px-4 p-4'
            >
                <div
                    style={exportHeadStyle}
                //className='d-flex align-items-center gap-3' .................
                >
                    <img
                        src={peabody}
                        style={imgSizeStyle}
                        //className='icon38px' 
                        alt='Peabody'
                    />
                    <img
                        src={goldImg}
                        style={{ ...imgSizeStyle, marginRight: 'auto' }}
                        //className='icon38px me-auto' 
                        alt='goldmund'
                    />

                    { orderInfo.Estado ?  orderInfo.Estado  === "Cargado en SAP" && <img src={downIco} alt='DescargarPDF'
                        className='contrast60 cursorPointer btnDownloadOrd'
                        onClick={() => pdfHandle(pdfRefOrd)}
                    /> : '' }

                    <button id=''
                        onClick={() => setExportModal(false)}
                        className="btn-close btnCardHead">
                    </button>

                </div>

                <div
                    style={{ ...rowStyle, ...cliInfoStyle }}
                //className='row border expDataBg pb-2 pt-4 px-2 my-2'
                >
                    <div
                        style={col4Style}
                    //  className='col-4 d-flex flex-column gap-1'
                    >
                        <div
                            style={exportDataStyle}
                        // className='exportData d-flex flex-column position-relative '
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >Proforma</span>

                            <span
                                style={labelSpanStyle}>
                                {
                                    orderInfo.Proforma
                                        ? <>
                                            {orderInfo.Proforma.numProforma} < br />
                                            {orderInfo.Proforma?.fecha}
                                        </>
                                        : '-'
                                }  </span>

                        </div>

                        <div
                            style={exportDataStyle}
                            //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                ID Pedido
                            </span>
                            <span
                                style={labelSpanStyle}>
                                {orderInfo.Num_pedido}
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Num Pedido
                            </span>
                            <span
                                style={labelSpanStyle}
                            >
                                {
                                    orderInfo.NumPedidoDelCliente
                                        ? orderInfo.NumPedidoDelCliente
                                        : '-'
                                }
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //  className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            //className='small fontSize11 exportOrdLabel'
                            >
                                Vendedor
                            </span>
                            <span style={labelSpanStyle}>
                                {orderInfo.VendedorApellido},&nbsp;
                                {orderInfo.VendedorNombre}
                            </span>
                        </div>
                    </div>

                    <div
                        style={col4Style}
                    //className='col-4 d-flex flex-column gap-1'
                    >
                        <div
                            style={exportDataStyle}
                        // className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Cliente
                            </span>
                            <span style={labelSpanStyle}>
                                {orderInfo.Cliente}
                            </span>
                        </div>
                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            //className='small fontSize11 exportOrdLabel'
                            >
                                Cuit
                            </span>
                            <span style={labelSpanStyle}>
                                {orderInfo.Cuit}
                            </span>
                        </div>
                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Domicilio
                            </span>
                            <span style={labelSpanStyle}>
                                {
                                    orderInfo.Direccion_Facturacion
                                        ? <>
                                            {
                                                orderInfo.Direccion_Facturacion
                                            },&nbsp;
                                            {
                                                orderInfo.Provincia_Facturacion
                                            }
                                        </>
                                        : '-'
                                }
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            //className='small fontSize11 exportOrdLabel'
                            >
                                Telefono
                            </span>
                            <span style={labelSpanStyle}>
                                {
                                    orderInfo.Telefono
                                        ? orderInfo.Telefono
                                        : '-'
                                }
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //  className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            //className='small fontSize11 exportOrdLabel'
                            >
                                Correo
                            </span>
                            <span style={labelSpanStyle}>
                                {
                                    orderInfo.EMail
                                        ? orderInfo.EMail
                                        : '-'
                                }
                            </span>
                        </div>
                    </div>


                    <div
                        style={col4Style}
                    //className='col-4 d-flex flex-column gap-1'
                    >

                        <div
                            style={exportDataStyle}
                        //  className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Fecha
                            </span>
                            <span style={labelSpanStyle}>
                                {orderInfo.Fecha}
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            //className='small fontSize11 exportOrdLabel'
                            >
                                Pago
                            </span>
                            <span style={labelSpanStyle}>
                                {orderInfo.PymntGroup.PymntGroup}
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Dom. de Entrega
                            </span>
                            <span style={labelSpanStyle}>
                                {
                                    (orderInfo.Direccion_Entrega_New || orderInfo.Direccion_Entrega)
                                        ?


                                        orderInfo.Direccion_Entrega_New
                                            ?
                                            orderInfo.Direccion_Entrega_New.ShipToStreet + ' ' +
                                            orderInfo.Direccion_Entrega_New.ShipToStreetNo + ' - ' +
                                            orderInfo.Direccion_Entrega_New.ShipToCity + ' ' +
                                            orderInfo.Direccion_Entrega_New.ShipToState + ' - ' +
                                            orderInfo.Direccion_Entrega_New.ShipToCountry

                                            :
                                            orderInfo.Direccion_Entrega + ' - ' + orderInfo.Provincia_Entrega

                                        : '-'
                                }
                            </span>
                        </div>

                        <div
                            style={exportDataStyle}
                        //  className='exportData d-flex flex-column position-relative'
                        >
                            <span
                                style={labelStyle}
                            // className='small fontSize11 exportOrdLabel'
                            >
                                Tel. Transporte
                            </span>
                            <span style={labelSpanStyle}>
                                {
                                    orderInfo.TelTransporte
                                        ? orderInfo.TelTransporte
                                        : '-'
                                }
                            </span>
                        </div>
                    </div>

                </div>

{ !isOpenModal.b && (
                <table
                    style={tableStyle}
                // className=' borderedTable my-2'
                >
                    <thead
                        style={{ ...tableBorderStyle, backgroundColor: '#f3f3f3' }}
                    //className='expDataBg'
                    >
                        <tr>
                            <th>Articulo</th>
                            <th>Descripcion</th>
                            <th>Cant</th>
                            <th>PVP</th>
                            <th>PVP S/IVA</th>
                            <th>MUP</th>
                            <th>COSTO S/IVA</th>
                            <th>TOTAL S/IVA</th>
                            {/* <th>BONO x PROD</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderInfo.DocumentLines.map((i) => (
                                <tr key={i.Articulo}>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {i.Articulo}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {i.Descripcion}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {i.Cantidad}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {formatter.format(i.Pvp)}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {formatter.format(i.PvpSinIva)}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {i.MUP}
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {/* {formatter.format(i.CostoSinIva)} */}
                                        { formatter.format(ContarCostoCorrectTemporal(i.Pvp,i.Bono,i.MUP,i.CostoSinIva)) }
                                    </td>
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                        {formatter.format(i.sumDupliCostoSinIva)} {/*  totalCostoSinIva  */}
                                    </td>
                                {/* 
                                    <td style={{ ...tableBorderStyle, textAlign: 'center', padding: '2px' }}>
                                            { formatter.format(i.Bono) }
                                    </td> 
                                */}
                                    </tr>
                            ))
                        }
                    </tbody>
                </table>
)}


                <table
                    style={detailsStyle}
                //className='exportDetTable my-4 border-bottom'
                >

                    <tbody >
                        <tr >
                            <td
                                style={{ fontSize: '0.875em' }}
                            //</tr>className='small'
                            >
                                NETO
                            </td>
                            <td
                                style={{ fontWeight: '700' }}
                            //className='fw-bold'
                            >
                                {
                                        orderInfo.sumCostoConIvaTripl &&
                                        whithIva
                                        ?formatter.format(orderInfo.sumCostoConIvaTripl)
                                        :formatter.format(orderInfo.Neto)
                                } 
                                {/* {formatter.format(orderInfo.Neto)} */}
                            </td>


                        </tr>

                        <tr>
                            <td
                                style={{ fontSize: '0.875em' }}
                            // className='small'
                            >
                                DC. &nbsp; {orderInfo.Descuento02} %
                            </td>
                            <td>$ {(orderInfo.Neto * (orderInfo.Descuento02 / 100)).toFixed(2)}</td>

                            <td
                                style={{ fontSize: '0.875em' }}
                            // className='small'
                            >
                                IIBB CABA
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.P007
                                        ? formatter.format(orderInfo.Proforma.P007)
                                        : '-'
                                }
                            </td>
                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.P007
                                        ? `${orderInfo.Proforma.PorcentajeIBB.P007.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>

                        </tr>

                        <tr>
                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                DF. &nbsp; {orderInfo.Descuento03} %
                            </td>
                            <td>$ {(orderInfo.Neto * (orderInfo.Descuento03 / 100)).toFixed(2)}</td>

                            <td
                                style={{ fontSize: '0.875em' }}
                            //  className='small'
                            >
                                IIBB BS AS
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.P013
                                        ? formatter.format(orderInfo.Proforma.P013)
                                        : '-'
                                }
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.P013 
                                        ? `${orderInfo.Proforma.PorcentajeIBB.P013.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>

                        </tr>

                        <tr>
                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                DPP. &nbsp; {orderInfo.Descuento01} %
                            </td>
                            <td>$ {(orderInfo.Neto * (orderInfo.Descuento01 / 100)).toFixed(2)}</td>

                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                IIBB TUC
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.PC01
                                        ? formatter.format(orderInfo.Proforma.PC01)
                                        : '-'
                                }

                            </td>
                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.PC01
                                        ? `${orderInfo.Proforma.PorcentajeIBB.PC01.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>
                        </tr>

                        <tr>
                            <td
                                style={{ fontSize: '0.875em' }}
                                //className='small'
                            >
                                SUBTOTAL
                            </td>
                            <td
                                style={{ fontWeight: '700' }}
                                //className='fw-bold'
                            >
                                   {
                                            orderInfo.SubTotal &&
                                            whithIva 
                                            ?formatter.format(orderInfo.sumTotalConIvaTripl)
                                            :formatter.format(orderInfo.SubTotal)
                                    } 
                                 {/* {formatter.format(orderInfo.SubTotal)} */}
                            </td>

                            <td
                                style={{ fontSize: '0.875em' }}
                                //className='small'
                            >
                                IIBB CORD
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.PC02
                                        ? formatter.format(orderInfo.Proforma.PC02)
                                        : '-'
                                }
                            </td>
                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.PC02
                                        ? `${orderInfo.Proforma.PorcentajeIBB.PC02.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>
                            
                        </tr>

                        <tr>
                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                IVA 21 %
                            </td>
                            <td >{formatter.format(orderInfo.IVA21)}</td>

                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                IIBB ER
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.P016
                                        ? formatter.format(orderInfo.Proforma.P016)
                                        : '-'
                                }
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.P016
                                        ? `${orderInfo.Proforma.PorcentajeIBB.P016.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>
                        </tr>

                        <tr>
                             <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                               {/* va solo */}
                            </td>
                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                              {/* va solo */}
                            </td>

                            <td
                                style={{ fontSize: '0.875em' }}
                            //className='small'
                            >
                                IIBB SFE
                            </td>

                            <td>
                                {
                                    orderInfo.Proforma.P016
                                        ? formatter.format(orderInfo.Proforma.stf)
                                        : '-'
                                }
                            </td>
                            <td>
                                {
                                    orderInfo.Proforma.PorcentajeIBB?.stf
                                        ? `${orderInfo.Proforma.PorcentajeIBB.stf.toFixed(2)}%` 
                                        : '-'
                                }
                            </td>
                        </tr>

                        <tr >
                            <td
                                style={{ fontSize: '0.875em', fontWeight: '700', paddingTop: '1rem' }}
                            //className='small pt-3 fw-bold'
                            >
                                Unidades: &nbsp; {sumProducts(orderInfo.DocumentLines)}
                            </td>

                            <td
                                style={{ fontWeight: '700', paddingTop: '1rem', textAlign: 'right' }}
                            // className='text-end pt-3 fw-bold'
                            >
                                TOTAL
                            </td>
                            <td
                                colSpan="2"
                                style={{ paddingTop: '1rem', paddingLeft: '1rem', fontWeight: '700', fontSize: '1.5em' }}
                            // className='pt-3 ps-3 fs-4 fw-bold'
                            >
                                {/* {
                                    orderInfo.Proforma.TotalConImp
                                        ?
                                        formatter.format(orderInfo.Proforma.TotalConImp)
                                        :
                                        formatter.format(orderInfo.Total)
                                    } */}

                                    {
                                        orderInfo.Proforma.TotalConImp
                                        ?
                                        formatter.format(orderInfo.Proforma.TotalConImp)
                                        :(
                                            orderInfo.Total &&
                                            whithIva
                                            ?formatter.format(orderInfo.sumTotalConIvaTripl)
                                            :formatter.format(orderInfo.Total)
                                        )
                                    }
                            </td>
                        </tr>

                    </tbody>
                </table>

                <p>
                    <b> Vigencia de la proforma:</b> 72hs hábiles. La presente no compromete stock ni reserva unidades,
                    puede variar según disponibilidad de la empresa.
                </p>

                <div
                    style={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}
                //className='my-4'
                >
                    <div
                        style={{ paddingBottom: '0.5rem' }}
                    //  className='pb-2'
                    >
                        <h5
                            style={{ fontWeight: '700' }}
                        //className='fw-bolder'
                        >
                            CUENTAS PARA DEPOSITAR
                        </h5>

                        <p>Cuenta corriente en pesos a nombre de <strong>GOLDMUND S.A. </strong><br />
                            CUIT: <b>30-70860387-9</b>
                        </p>
                    </div>
                    {
                        cuentasBanca.map((banco) => (
                            <div
                                key={banco.bco}
                                style={bankLineStyle}
                            //className="d-flex flex-wrap  fontSizeSmallSM borderB"
                            >
                                <span
                                    style={{ fontWeight: '700', width: '140px' }}
                                // className='fw-bold widthBancos'
                                >
                                    {banco.bco}
                                </span>
                                <div
                                    style={ccStyle}
                                // className='ms-auto widthCuentaCorr text-end pe-2'
                                >
                                    <span
                                        style={numStyle}
                                    //className='fontSize13 me-2  fw-bold'
                                    >
                                        C/C
                                    </span>
                                    <span>
                                        {banco.cc}
                                    </span>
                                </div>
                                <div
                                    style={{ marginLeft: 'auto', paddingRight: '0.5rem', paddingLeft: '1rem' }}
                                //className='ms-auto pe-2 ps-3'
                                >
                                    <span
                                        style={numStyle}
                                    //className='fontSize13 me-2 fw-bold'
                                    >
                                        CBU
                                    </span>
                                    <span >
                                        {banco.cbu}
                                    </span>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <p
                    style={{ fontSize: '.875em' }}
                // className='small'
                >
                    <b>NOTA:</b>  EL VALOR EXPRESADO EN LA NOTA DE PEDIDO ES CON IVA. GOLDMUND S.A ES AGENTE DE
                    PERCEPCION DE INGRESOS BRUTOS EN LA PROV DE BS.AS. Y C.A.B.A, POR LO QUE SE INCLUIRA LA
                    ALICUOTA CORRESPONDIENTE EN CADA FACTURA, EL CUAL DEBERA SER ABONADA AL MOMENTO DE EMISION.
                </p>

                <p
                    style={{ fontSize: '.875em' }}
                // className='small'
                >
                    <b>*</b>  LAS CONDICIONES PRESENTES PUEDEN CAMBIAR ANTE FLUCTUACIONES DE CONTEXTO. LISTA DE PRECIOS SUJETA A
                    MODIFICACION SIN PREVIO AVISO Y A EVALUACION COMERCIAL <b>*</b>
                </p>
            </div>
        </dialog >
    )
}
