import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import clientsReducer from './clients/ClientSlices'
import clientSyncReducer from './clients/ClientSyncSlices'
import orderDataReducer from './orderData/OrderDataSlices'
import OrdersReducer from './orderData/OrdersSlices'
import downloadingReducer from './indicators/DownloadingSlices'
import downloadElementsReducer from './indicators/DownloadElementsSlices'
import userReducer from './userdata/UserSlices'
import priceReducer from './prices/PriceSlices'
import priceSyncReducer from './prices/PriceSyncSlices'
import openModalReducer from './openModal/OpenModalSlices'
import clientInfoReducer from './clientInfo/ClientInfoSlices'
import ProductInfoReducer from './productInfo/ProductInfoSlices'
import MessagesReducer from './messages/MessagesSlices'
import searchFilterReducer from './searchFilter/SearchFilterSlices'
import gerenciasReducer from './gerencias/GerenciasSlices'
import messagesUIDsReducer from './messages/MessagesUIDsSlices'
import refMessageReducer from './messages/RefMessageSlices'
import sapConnectedReducer from './indicators/SapConnectedSlices'
import newMessageIndiReducer from './indicators/NewMessageIndiSlices'
import showDivIdReducer from './messages/ShowDivIdSlices'
import directionsReducer from './clients/DirectionsSlices'
import condicionesDePagoReducer from './condicionesDePago/CondicionesDePagoSlices'
import pendantOrderReducer from './indicators/PendantOrderSlices'
import loggedReducer from './indicators/LoggedSlices'
import sapStateReducer from './indicators/SapStateSlices'
import userListReducer from './adminPanel/UserListSlices'
import openDetailReducer from './openModal/OpenDetailSlices'
import testIndicatorReducer from './indicators/TestIndicatorSlices'
import TotalGerenSlice from './gerencias/TotalGerenSlice'
import fileReducer from './orderData/OrderFileSlice'
import vendedoresReducer  from './gerencias/vendedoresSlices';
//Configuramos el store donde guardaremos los datos

export const store = configureStore({
    reducer: {
        vendedores: vendedoresReducer,
        clients: clientsReducer,
        clientSync: clientSyncReducer,
        price: priceReducer,
        priceSync: priceSyncReducer,
        user: userReducer,
        downloading: downloadingReducer,
        downloadElements: downloadElementsReducer,
        orderData: orderDataReducer,
        openModal: openModalReducer,
        clientInfo: clientInfoReducer,
        productInfo: ProductInfoReducer,
        messages: MessagesReducer,
        orders: OrdersReducer,
        searchFilter: searchFilterReducer,
        gerencias: gerenciasReducer,
        messagesUIDs: messagesUIDsReducer,
        refMessage: refMessageReducer,
        sapConnected: sapConnectedReducer,
        newMessageIndi: newMessageIndiReducer,
        showDivId: showDivIdReducer,
        clientsDirections: directionsReducer,
        condicionesDePago: condicionesDePagoReducer,
        pendantOrder: pendantOrderReducer,
        logged: loggedReducer,
        sapState: sapStateReducer,
        userList: userListReducer,
        openDetail: openDetailReducer,
        testIndicator: testIndicatorReducer,
        totalGeren:TotalGerenSlice,
        fileData: fileReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignorar el chequeo para la acción `fileData/setFile`
          ignoredActions: ['fileData/setFile'],
          ignoredPaths: ['file'], // Ignorar rutas donde se almacenen archivos no serializables
        },
      }),
})