import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ID: "",
    NumPedidoDelCliente: "",
    Cliente: "",
    Cuit: "",
    Descuento01: '',
    Descuento02: '',
    Descuento03: '',
    DescuentoTotal: 0,
    Direccion_Facturacion: "",
    Provincia_Facturacion: "",
    Telefono: "",
    Direccion_Entrega: "",
    Provincia_Entrega: "",
    EMail: "",
    MUPPactado: "",
    TelTransporte: "",
    Transporte: "",
    VendedorNombre: "",
    VendedorApellido: "",
    // PagoEntregaValores: "",
    PlazoDePagoValores: "",
    Fecha: "",
    GrupoDeCompras: "",
    IIBBBsAs: 0,
    IIBBCABA: 0,
    IVA21: 0,
    SlpCode: '',
    SumPvp: 0,
    SumPvpSinIva: 0,
    SubTotal: 0,
    SubTotalSinIva: 0,
    Total: 0,
    TipoPedido: '1',
    CategoriaMup: [],
    Comments: '',
    FechaDeRevision: '',
    Turno: '',
    MesPactado: '0',
    AnioSelecc: '0',
    MesSelecc: '0',
    CantEntregas: '1',
    Contacto_Turno: '',
    PymntGroup: { Code: '', PymntGroup: '' },
    DocumentLines: [{ Articulo: '', Cantidad: '', MUP: '', Bono:'' }],
    Proforma: false,
    RegistroOriginales: []
}


export const OrderFormSlices = createSlice({
    name: 'orderData',
    initialState,
    reducers: {
        addShipToDef: (state, action) => {
            return {
                ...state,
               ShipToDef : action.payload
            }
        },
        addShipToDef: (state, action) => {
            return {
                ...state,
               ShipToDef : action.payload
            }
        },
        addOrderData: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetOrderData: (state) => {
            return initialState
        },
        addProduct: (state, action) => {
            return {
                ...state,
                DocumentLines: [...state.DocumentLines, { Articulo: '', Cantidad: '', MUP: action.payload, Bono:'' }]
            }
        },
        addCategoryMup: (state, action) => {
            return {
                ...state,
                CategoriaMup: [...state.CategoriaMup, action.payload],
                Comments: action.payload + ' ' + state.Comments
            }
        },
        removeCategoryMup: (state, action) => {
            const regex = new RegExp(`\\b${action.payload}\\b`);
            return {
                ...state,
                CategoriaMup: state.CategoriaMup.filter((cat) => cat !== action.payload),
                Comments: state.Comments.replace(regex, '')
            }
        },
        selectOrder: (state, action) => {
            return {
                ...action.payload
            }
        },
        addModification: (state, action) => {
            return {
                ...state,
                Modificador: action.payload,
                RegistroOriginales: [
                    ...state.RegistroOriginales,
                    {
                        ...state,
                        Estado: 'Modificado',
                        RegistroOriginales: []
                    }
                ]
            }
        },
    }
})

export const {
    addOrderData, resetOrderData, addProduct, addCategoryMup, removeCategoryMup,addShipToDef,
    selectOrder, addModification
} = OrderFormSlices.actions
export default OrderFormSlices.reducer