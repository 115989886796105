import { useDispatch, useSelector } from 'react-redux'
import editIco from '../../assets/images/editIco.png'
import { modalOpen } from '../../store/openModal/OpenModalSlices'
import { GlobalFilter } from '../tools/index'
import pdfDown from '../../assets/images/pdfDown.png'

export const CardHead = ({ section, searchBar = false,  closeSection, setExportModal }) => {

    const dispatch = useDispatch()
    const clientInfo = useSelector(state => state.clientInfo)
    const orderInfo = useSelector(state => state.orderData)
    const productInfo = useSelector(state => state.productInfo)
 
    // console.log(clientInfo)

    const handleExportOrder = () => {
        if (orderInfo.Estado !== 'Modificado') {
            setExportModal(true)
        } else {
            alert("La orden fue modificada")
        }
    }

    // casos en que se muestra la seccion, el cliente, botones etc..
    return (

        <div className="px-2 d-flex justify-content-between align-items-center clrBgPink index2000 position-sticky top-0 cardHead">

            {/* titulo de la seccion en usuario, pedidos, clientes, precios,
             notificaciones, crear pedido, editar datos */}
            {
                (
                    section !== 'CLIENTE'
                    && section !== 'PRODUCTO'
                    && section !== 'ORDEN'
                )
                &&
                <span className={`p-2 bd-highlight textShadowLight fontSize18 fs-5 
                ${(section === 'CREAR PEDIDO' || section === 'EDITAR DATOS')
                        ? '' : 'displayNoneSM'}`}
                >
                    {section}
                </span>
            }
            {/* buscador cuando es necesario */}
            {
                searchBar
                &&
                <GlobalFilter section={section} />
            }
            {/* encabezado en cada pedido, cliente, producto */}
            {
                (
                    section === 'ORDEN'
                    || section === 'CLIENTE'
                    || section === 'PRODUCTO'
                )
                &&
                <>
                    <span className='p-2 text-uppercase textEllipsis fs-5 '>
                        {
                            section === 'ORDEN'
                                ? orderInfo.Cliente
                                : section === 'CLIENTE'
                                    ? clientInfo.Cliente
                                    : productInfo.ItemName
                        }
                    </span>
                </>
            }
            {/* boton de editar en usuario */}
            {
                (section === 'MI CUENTA')
                &&
                <img
                    onClick={() => dispatch(modalOpen({ a: true }))}
                    className='centerEnd me-2 btnCardHead cursorPointer ms-auto index2000 editUserBtn'
                    src={editIco} 
                    alt="Editar"
                    
                />

            }

            {/* TODO: revisar casos responsive */}
            {/* boton de cerrar en casos necesarios */}
            {
                section === 'ORDEN'
                &&
                <img
                    src={pdfDown}
                    className='ms-auto icon30px btnCardHead cursorPointer contrast60'
                    onClick={handleExportOrder}
                    alt='PDF'
                />
            }
            {
                closeSection
                &&
                <button className="btn-close centerEnd btnCardHead ms-4 p-0 me-2" onClick={closeSection} />
            }
        </div>
    )
}
